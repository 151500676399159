<template>
  <div class="index-wrap">
    home
  </div>

</template>

<script>
import actions from "@/shared/actions";
export default {
  name: 'index',
  components: {},
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    actions.onGlobalStateChange((state) => {
      console.log(state)
    }, true);
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
  .index-wrap {

  }
</style>
